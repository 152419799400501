<template>
  <div>
    <el-checkbox-group v-model="checkList"  style="display: flex;
                        flex-flow: column nowrap;
                        align-items: flex-start;
                        margin-bottom: 30px;"
                        @change="getChange">
      <el-checkbox style="margin-top: 15px;" label="1">售后消息</el-checkbox>
      <el-checkbox style="margin-top: 15px;" label="2">故障消息</el-checkbox>
      <el-checkbox style="margin-top: 15px;" label="3">销售合伙人消息</el-checkbox>
      <el-checkbox style="margin-top: 15px;" label="4">押金消息</el-checkbox>
      <el-checkbox style="margin-top: 15px;" label="5">其它异常消息</el-checkbox>
	  
	  
	  <el-checkbox style="margin-top: 15px;" label="6">电量为零消息</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="7">超出电子围栏消息</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="8">FautBat</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="9">FautBat1</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="10">FautDisP</el-checkbox>
	  
	  <el-checkbox style="margin-top: 15px;" label="11">FautCombinSwitch</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="12">FautEC9</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="13">FautECA</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="14">FautECB</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="15">FautECC</el-checkbox>
	  
	  <el-checkbox style="margin-top: 15px;" label="16">PWMState</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="17">BatWake</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="18">Bat1Wake</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="19">FautBrake</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="20">FautThro</el-checkbox>
	  
	  <el-checkbox style="margin-top: 15px;" label="21">FautSteel</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="22">FautVCU</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="23">断电超出时长</el-checkbox>
	  <el-checkbox style="margin-top: 15px;" label="24">超时未开锁运营</el-checkbox>
    </el-checkbox-group>
    <div style="text-align:right;margin-top:20px">
      <el-button
        type="primary"
        @click="selectClassify"
      >确定</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "goods",
  props: {
    defaultData: {
      type: Array,
      // default: () => {},
    },
    show: {
      type: Boolean,
      // default: false,
    },
  },
  data() {
    return {
      checkList: [],
    };
  },
  watch: {
    show: { 
      handler(newValue) { 
        this.onLoad();
    }, 
    immediate: true
  }
  },
  methods: {
    onLoad() {
      this.checkList = this.defaultData
    },
    getChange(e) {
      this.checkList = e
      console.log(this.checkList,'111111');
    },
    selectClassify() {
      this.$emit("change", this.checkList);
    },
    // selectionChange(list) {
    //   this.selectList = list;
    //   this.$emit("newchange", this.selectList);
    // },
  },
};
</script>

<style scoped>
/* .crud /deep/.el-card__body .el-form .el-table .el-table__fixed-right {
  height: 100% !important;
} */
</style>
